import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'

const PortfolioSection = () => {
    return (
        <section id = 'portfolio-section' style = {sectionStyle}>

         <div style = {headingWrapperStyle}>
            <h1 style = {headingStyle} className = 'portfolio-heading'>Portfolio</h1>
            <p style = {subTextStyle} className = 'portfolio-subtext'><span className = 'line-1'>Select a project to view</span> <span className = 'line-2'>in full screen</span></p>
         </div>

         <div style = {portfolioWrapperStyle} className = 'portfolio-wrapper'>
           <div className = 'container-fluid'>
              <div className = 'row portfolio-gutter'>
                <div className = 'portfolio-col col-lg-3 col-md-6  offset-lg-0 col-sm-6 col-6 text-center'>
                  <Link to = '/graphicdesign' style = {linkStyle} > 
                  <div className = 'portfolio-select-wrapper mt-2' style = {selectStyle}>
                  
                           <img src = '/illustration.png' className = 'portfolio-select-image' style = {illustrationStyle}></img>
                            <h6 style = {selectHeadingStyle} className = 'select-heading'>Graphic Design</h6>
                    </div>
                    </Link>
                </div>
                <div className = 'portfolio-col col-lg-3 col-md-6  col-sm-6 col-6 text-center'>
                  <Link to = '/illustrations' style = {linkStyle}>
                  <div className = 'portfolio-select-wrapper mt-2' style = {selectStyle}>
                    
                           <img src = '/illustration-pen.png' className = 'portfolio-select-image' style = {illustrationStyle}></img>

                            <h6 style = {selectHeadingStyle} className = 'select-heading'>Illustration</h6>
                    </div>
                    </Link>
                </div>
                  
                <div className = 'portfolio-col col-lg-3 col-md-6  offset-lg-0 col-sm-6 col-6 text-center'>
                  <Link to = '/advertisements' style = {linkStyle}>
                  <div className = 'portfolio-select-wrapper mt-2 select-bottom' style = {selectStyle}>
                
                            <img src = '/illustration-newspaper.png' className = 'portfolio-select-image' style = {illustrationStyle}></img>

                            <h6 style = {selectHeadingStyle} className = 'select-heading'>Advertising</h6>
                    </div>
                    </Link>
                </div>
                <div className = 'portfolio-col col-lg-3 col-md-6 col-sm-6 col-6 text-center'>
                <Link to = '/uxdesign' style = {linkStyle}>
                  <div className = 'portfolio-select-wrapper mt-2 select-bottom' style = {selectStyle}>
                
                            <img src = '/illustration-ux.png' className = 'portfolio-select-image' style = {illustrationStyle}></img>
                            <h6 style = {selectHeadingStyle} className = 'select-heading'>UX/UI Desigin</h6>
                    </div>
                    </Link>
                </div>
                
                
              </div>
           </div>
         </div>

        </section>
    )
}

const sectionStyle = {
    minHeight:'60vh',
    paddingLeft:'100px',
    paddingRight:'100px'
}

const headingWrapperStyle = {
    textAlign:'center',
    color:'#464748',
    marginTop:'50px'
  }
  
  const headingStyle = {
    fontSize:'50px',
    fontWeight:'normal'
  }
  
  const subTextStyle = {
    fontSize:'22px'
  }

  const portfolioWrapperStyle = {
      paddingTop:'70px'
  }

  const selectStyle = {
    width:'280px',
    height:'280px',
    border:"1px solid red",
    borderRadius:'16px',
    margin:'0 auto',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center'
  }

  const illustrationStyle = {
      height:'180px',
      width:'180px',
      objectFit:'contain'
  }

  const selectHeadingStyle = {
    fontSize:'22px',
    fontWeight:'normal',
    marginTop:'20px'
  }

  const linkStyle = {
    textDecoration:'none',
    color:'inherit'
  }

export default PortfolioSection
