import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import PortfolioSection from "../components/PortfolioSection"

const portfolio = () => (
  <Layout>
    <Seo title="Portfolio" />
    
    <PortfolioSection />
  </Layout>
)



export default portfolio
